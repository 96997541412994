import axios from 'axios'

const setupHeaderConfig = (config) => {
  // -------------------------------------------------------------
  // Note: This will be uncommented once I finish the auth state / store
  // -------------------------------------------------------------
  // const token
  const token = localStorage.getItem('token')
  // !config.headers ? config.headers = {} : null\
  if (config.headers === undefined) {
    config.headers.Authorization = 'Bearer ' + token
  } else {
    config.headers.Authorization = 'Bearer ' + token
  }
  return config
}

export default ({
  state: {

  },
  mutations: {

  },
  getters: {

  },
  actions: {
    async reportAccount ({ commit }, params, config = { headers: {} }) {
      // eslint-disable-next-line no-console
      /* eslint-disable */
            console.log(commit);
            const newConfig = setupHeaderConfig(config);
            return await axios.post('admin-panel/report/action', params, newConfig);
        },
    }
})