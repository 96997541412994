<template>
  <div class="col-md-12">
    <div class="row">
      <div class="col-sm-3">
        <label
          for="inputEmail3"
          class="col-sm-12 col-form-label ml-2 mt-2 mb-1"
        >Send To:</label>
      </div>
      <div class="col-sm-9 row px-2" v-if="messageTo === 'Selected' || messageTo === 'specific_user'">
        <select class="form-select form-select-sm mt-2" @change="getMessageType($event)">
          <option value="selectedUser" :selected="messageTo === 'Selected' || messageTo === 'specific_user'">Selected User</option>
          <option value="allUser" :selected="messageTo === 'Select All'">Select All</option>
        </select>
      </div>
    </div>
    <div class="row px-2">
      <div class="col-sm-12">
        <user-input
          v-if="((messageTo === 'Selected' || messageTo === 'specific_user') && messageType === 'selectedUser')"
          v-model="selectedData"
          :value="selectedData"
          element-id="tags"
          :existing-tags="respondsRecord"
          :typeahead="true"
          :only-existing-tags="true"
          :typeahead-hide-discard="true"
          :placeholder="`Add ${selectedType}`"
          :id-field="`id`"
          :text-field="`name`"
          :class="`mt-1 mb-1 tag-input`"
          @tag-added="onDataAdded"
          @tag-removed="onDataRemoved"
          @change="getData"
        />
        <div class="text-center font-italic mt-2" v-else-if="messageType === 'allUser'">
          <label>You are sending a message to all PF Members.</label>
        </div>
        <label
          v-else
          class="col-sm-12 col-form-label mt-2 mb-1 tag-input"
        > {{ messageTo }}  <span v-if="selectedType === 'User'">{{ moment().format("ll") }}</span></label>
      </div>
    </div>
    <hr style="width: 100%">
    <div class="row">
      <div class="col-sm-12">
        <div class="wysiwyg-editor px-2 py-2">
          <!-- attachment preview -->
          <div class="attachment-lists bg-light-gray">
            <div
              ref="attachmentsRef"
              class="attachmentsList"
            >
              <div
                v-if="isUploadingPhoto"
                class="w-100 h-100 has-image mx-1"
              >
                <div class="w-100 h-100 position-relative photo-picker">
                  <img
                    :src="placeholder"
                    class="mw-100 mh-100 rounded-2"
                  >
                  <i class="fa fa-spinner" />
                </div>
              </div>
              <div
                v-for="(row, index) in attachmentMediaIds"
                :key="index"
                class="w-100 h-100 has-image mx-1"
              >
                <div class="w-100 h-100 has-image mx-1">
                  <div class="w-100 h-100 position-relative photo-picker">
                    <input
                      type="file"
                      class="d-none"
                      accept="image/png,image/jpeg,image/gif,image/webp,image/svg+xml"
                    >
                    <button
                      type="button"
                      class="btn btn-transparent p-0 img-upload-btn position-relative overflow-hidden w-100 h-100"
                      :disabled="isUploadingPhoto"
                    >
                      <img
                        :src="row.original"
                        class="mw-100 mh-100 rounded-2"
                        :alt="row.client"
                      >
                    </button>
                    <template v-if="!isUploadingPhoto">
                      <button
                        type="button"
                        class="btn p-0 remove-btn"
                        style="position: absolute; "
                        @click="removePhoto(row)"
                      >
                        <i class="fa-solid fa-trash-can" />
                      </button>
                    </template>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ce-chat-box
            v-model="contentMessage"
            class="chat-box"
            placeholder="Write your message..."
          />
        </div>
        <div class="modal-footer border-0 justify-content-between">
          <div class="message-actions d-flex align-items-left">
            <input
              ref="fileBrowser"
              type="file"
              class="d-none"
              multiple="multiple"
              accept="image/*"
              @change="pick($event)"
            >
            <!-- <button class="border-0 bg-white" @click="$refs.fileBrowser.click()">
              <i class="fa-solid fa-paperclip"></i>
            </button> -->
            <button
              class="border-0 bg-white"
              @click="$refs.fileBrowser.click()"
            >
              <i class="fa-solid fa-file-image" />
            </button>
          </div>
          <div>
            <span
              type="button"
              class="btn-outline"
              style="color: #6c757d;"
              @click="cancelMessage"
            >CANCEL</span>
            <span
              type="button"
              class="send ml-2"
              :is-loading="isLoading"
              :disabled="isUploadingPhoto"
              @click="sendMessage"
            >SEND</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, watch } from 'vue'
import { useModal } from '../../composable/use-modal.js'
import UserInput from '@voerro/vue-tagsinput'
import CeChatBox from '@/components/Editors/CeChatBox.vue'
import moment from 'moment'
import { useStore } from 'vuex'
import $ from 'jquery'
import debounce from 'lodash/debounce'
export default {
  components: {
    UserInput,
    CeChatBox
  },
  props: {
    messageTo: {
      type: String,
      required: true,
      default: ref('Selected')
    },
    selectedType: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      default: '//via.placeholder.com/150'
    },
    messageUser: {
      type: Object,
      required: false
    }
  },
  emits: ['cancelMessage'],
  setup (props, { emit }) {
    const store = useStore()
    const { modalRef, open: openModal, close } = useModal()
    const type = ref(null)
    const contentMessage = ref(null)
    const respondsRecord = ref([])
    const attachments = ref([])
    const attachmentTypes = ref([])
    const uploadMaxSize = 10240
    const attachmentMediaIds = ref([])
    const isUploadingAttachments = ref(false)
    const chatBoxIsEmpty = computed(() => !contentMessage.value)
    const attachmentsRef = ref(null)
    const refFiles = ref(null)
    const input = document.querySelector('input[name=tags]')
    const selectedTagData = ref([])
    const fileBrowser = ref(null)
    const selectedData = ref([])
    const isLoading = ref(false)
    const isUploadingPhoto = ref(false)
    const imageSrc = ref([])
    const messageType = ref('selectedUser')

    const getData = debounce( async (search) => {
      const records = ref([])
      const response = []
      if (props.selectedType === 'User') {
        response.value = await store.dispatch('selectUserToMessage', search)
        records.value = response.value
      }
      if (props.selectedType === 'Group') {
        response.value = await store.dispatch('group/selectGroupToMessage', search)
        records.value = response.value
      }
      if (props.selectedType === 'Event') {
        response.value = await store.dispatch('selectEventToMessage', search)

        const data = JSON.parse(JSON.stringify(response.value))
        const array = []
        data.forEach((value) => {
          array.push({
            id: value.id,
            name: value.title
          })
        })
        records.value = array
      }
      respondsRecord.value = records.value
    }, 750)

    const open = (messageType) => {
      type.value = null
      if (messageType === 'selected') {
        type.value = 'Selected'
      }

      getData(null)
      openModal()
      return true
    }

    const enableSend = computed(
      () => !chatBoxIsEmpty.value || !isUploadingAttachments.value
    )
    const clearAttachments = () => {
      attachments.value = []
    }

    const allowedAttachment = (types) => {
      return attachmentTypes.value.findIndex((v) => types === v) !== -1
    }

    const addAttachments = (files) => {
      const formData = new FormData()
      for (let ctr = 0; ctr < files.length; ctr += 1) {
        formData.append('file[]', files[ctr])
      }

      submitFiles(formData)
    }

    const pick = (e) => {
      const mediaFiles = [...e.target.files]
      addAttachments(mediaFiles)
    }

    const onDataAdded = (value) => {
      selectedTagData.value.push({
        id: value.id,
        name: value.name
      })
    }

    const onDataRemoved = (value) => {
      const index = selectedTagData.value.indexOf(value.id)
      selectedTagData.value.splice(index, 1)
    }

    const removePhoto = (value) => {
      const index = attachmentMediaIds.value.indexOf(value.id)
      attachmentMediaIds.value.splice(index, 1)
    }

    const submitFiles = async (formData) => {
      isUploadingPhoto.value = true
      const response = await store.dispatch('multipleMediaUpload', formData)

      response.forEach((element) => {
        attachmentMediaIds.value.push(element)
      })

      isUploadingPhoto.value = false
    }

    const sendMessage = async () => {
      /*
       * Strip tags, inbox always add <p> tag to every message even it only has white space
       * Remove white space
       * Checking if message is not empty
       */
      const html = contentMessage.value
      const div = document.createElement('div')
      div.innerHTML = html
      const message = div.textContent || div.innerText || ''

      // Send if there's a message or if there's an attachment and uploading is already finished
      if (message || attachmentMediaIds.value.length > 0) {
        try {
          isLoading.value = true

          // HERE WILL SEND THE FORM
          if (props.messageTo === 'Selected') {
            messageType.value = 'Selected'
          } else if (props.messageTo === 'specific_user') {
            selectedTagData.value = []
            selectedTagData.value.push({
              id: props.messageUser.id,
              name: props.messageUser.first_name + ' ' + props.messageUser.last_name
            })
            messageType.value = 'Selected'
          } else if (messageType.value === 'allUser') {
            messageType.value = 'allUser'
          } else {
            messageType.value = props.selectedType === 'User' ? 'Birthdate' : props.selectedType === 'Group' ? 'All Group' : 'All Event'
          }

          const form = {
            content: message,
            attachments: attachmentMediaIds.value,
            type: messageType.value,
            user_ids: selectedTagData.value,
            send_to: props.selectedType
          }

          await store.dispatch('sendMessage', form)
        } catch (error) {
          // eslint-disable-next-line no-console
          /* eslint-disable */
          console.log(error);
        } finally {
          cancelMessage();
        }
      }

      return false;
    };

    const cancelMessage = () => {
      $(".tags-input-badge-selected-default").remove();
      selectedData.value = [];
      attachmentMediaIds.value = [];
      selectedTagData.value = [];
      contentMessage.value = '';
      messageType.value = '';
      respondsRecord.value = [];
      emit('cancelMessage');
    }

    const getMessageType = (event) => {
      messageType.value = event.target.value
    }

    watch(() => props.messageUser, (newValue) => selectedData.value.push({ id: newValue.id, name: newValue.first_name + ' ' + newValue.last_name }))

    return {
      modalRef,
      open,
      close,
      type,
      input,
      respondsRecord,
      getData,
      contentMessage,
      addAttachments,
      clearAttachments,
      allowedAttachment,
      uploadMaxSize,
      attachments,
      attachmentTypes,
      attachmentMediaIds,
      isUploadingAttachments,
      sendMessage,
      enableSend,
      attachmentsRef,
      refFiles,
      pick,
      fileBrowser,
      selectedData,
      onDataAdded,
      selectedTagData,
      onDataRemoved,
      isLoading,
      isUploadingPhoto,
      removePhoto,
      imageSrc,
      moment,
      submitFiles,
      cancelMessage,
      messageType,
      getMessageType
    };
  },
};
</script>
<style lang="scss" scoped>
  hr:not([size]) {
    height: 0px !important;
  }
  label.col-form-label {
    color: #505050;
    font-size: 14px;
    font-weight: 400 !important;
  }

.bg-linear {
  background: linear-gradient(90deg, #b28f49 0%, #d6caaa 100%);
  border-radius: 4px 4px 0 0;
}

.attachmentsList {
  display: flex;

  > :deep(div) {
    margin: 0.25rem 0;
    width: 60px !important;
    height: 60px !important;
  }
  > :deep(div img) {
    background-color: gray;
  }
}

.ce-file-attachments {
  :deep(.files) {
    display: flex;
  }

  :deep(.ce-file-attachment) {
    .preview {
      width: 40px;
      height: 40px;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    img {
      object-fit: contain;
    }

    .file-name {
      display: none;
    }

    .delete-button {
      display: none;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      margin: 0;
      padding: 0;
    }

    &:hover {
      .delete-button {
        display: block;
      }
    }
  }
}

.remove-btn {
  display: none;
}

.photo-picker {
  &:hover .remove-btn {
    background: #E7E7E7;
    border-radius: 50%;
    box-shadow: 0 4px 10px rgba(black, .25);
    color: #212121;

    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    position: absolute;
    top: 0;
    right: 0;
    font-size: 1rem;
    z-index: 2;
  }
}

.img-upload-btn {
  position: relative;

  > .icon-outline-plus {
    display: none;
  }

  &:hover {
    &::before {
      content: ' ';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: #222;
      opacity: 0.5;
      z-index: 1;
    }

    > .icon-outline-plus {

      display: block;
      color: #fff;
      font-size: 2rem;
      z-index: 1;
    }
  }
}

.badge-number {
  position: absolute;
  right: 0;
  background-color: rgb(180, 145, 77);
  height: 30px;
  width: 30px;
  border-radius: 15px;
  margin: 2px;
  color: white;
  font-size: 1em;
  line-height: 1.8em;
  text-align: center;
}
.send {
    color: rgb(4, 98, 193);
}
.bg-light-gray {
  background-color: #e7e7e7;
}
.form-select-sm {
  background-color: #fff !important;
  border-color: rgb(206, 206, 206) !important;
  padding: 5px !important;
}
@import './../../assets/css/vue-select-input.css';
</style>
