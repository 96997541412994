import ManageUser from '@/Pages/User/ManageUser.vue'
import UserGroups from '@/Pages/User/UserGroup.vue'
import UserEvents from '@/Pages/User/UserEvent.vue'
import ResetPassword from '@/Pages/User/ResetPassword.vue'
import UpdateProfile from '@/Pages/User/UpdateProfile.vue'
import UserAction from '@/Pages/Report/UserAction.vue'

export default [{
        path: '/manage-users',
        name: 'ManageUser',
        props: { moduleName: `user` },
        component: ManageUser
    },
    {
        path: '/user-groups/:user_name',
        name: 'UserGroups',
        props: { moduleName: `user` },
        component: UserGroups
    },
    {
        path: '/user-events/:user_name',
        name: 'UserEvent',
        props: { moduleName: `user` },
        component: UserEvents
    },
    {
        path: '/user/reset-password/:user_name/:reset_type',
        name: 'ResetPassword',
        props: { moduleName: `user` },
        component: ResetPassword
    },
    {
        path: '/user/flag/:user_name',
        name: 'Flag',
        props: { moduleName: `user` },
        component: UserAction
    },
    {
        path: '/user/remove-flag/:user_name',
        name: 'RemoveFlag',
        props: { moduleName: `user` },
        component: UserAction
    },
    {
        path: '/user/suspend/:user_name',
        name: 'Suspend',
        props: { moduleName: `user` },
        component: UserAction
    },
    {
        path: '/user/unsuspend/:user_name',
        name: 'UnSuspend',
        props: { moduleName: `user` },
        component: UserAction
    },
    {
        path: '/user/deactivate/:user_name',
        name: 'Deactivate',
        props: { moduleName: `user` },
        component: UserAction
    },
    {
        path: '/user/update-profile/:user_name',
        name: 'UpdateProfile',
        props: { moduleName: `user` },
        component: UpdateProfile
    },
    {
        path: '/user/delete/:slug',
        name: 'DeleteUser',
        props: { moduleName: `user` },
        component: UserAction
    },
    {
        path: '/user/reactivate/:slug',
        name: 'ReactivateUser',
        props: { moduleName: `user` },
        component: UserAction
    }
]
