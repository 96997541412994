import axios from 'axios'

const setupHeaderConfig = (config) => {
    // -------------------------------------------------------------
    // Note: This will be uncommented once I finish the auth state / store
    // -------------------------------------------------------------
    // const token
    const token = localStorage.getItem('token')
        // !config.headers ? config.headers = {} : null\
    if (config.headers === undefined) {
        config.headers.Authorization = 'Bearer ' + token
    } else {
        config.headers.Authorization = 'Bearer ' + token
    }

    return config
}

export default ({
    state: {
        userList: [],
        userStatistics: [{
            icon: 'user',
            labels: 'Users',
            data: 0,
            link: '/manage-users',
            heading: [],
            menu1: [{
                    label: 'Reset password',
                    link: '/manage-users?type=reset-password'
                },
                {
                    label: 'Edit user information',
                    link: '/manage-users?type=update-profile'
                },
                {
                    label: 'Message user',
                    link: '/manage-users?type=message'
                },
                {
                    label: 'Flag a user',
                    link: '/manage-users?type=flag'
                }
            ],
            menu2: [{
                    label: 'Suspend a user',
                    link: '/manage-users?type=suspend'
                },
                {
                    label: 'Deactivate a user',
                    link: '/manage-users?type=deactivate'
                },
                {
                    label: 'Reactivate a user',
                    link: '/manage-users?type=reactivate'
                },
                {
                    label: 'Delete a user',
                    link: '/manage-users?type=delete'
                }
            ]
        }],
        enums: {
            children: [],
            bodyType: [],
            drinking: [],
            educationalLevel: [],
            ethnicity: [],
            gender: [],
            incomeLevel: [],
            interest: [],
            relationshipStatus: [],
            smoking: [],
            zodiacSign: []
        },
        profile: {},
        authUser: {}
    },
    mutations: {
        LOAD_USER_STATISTICS(state, payload) {
            state.userStatistics[0].heading = payload.statistics
            state.userStatistics[0].data = payload.all
        },
        LOAD_USERS(state, payload) {
            state.userList = payload
        },
        SET_ENUMS_CHILDREN(state, payload) {
            state.enums.children = payload
        },
        SET_ENUMS_BODY_TYPE(state, payload) {
            state.enums.bodyType = payload
        },
        SET_ENUMS_DRINKING(state, payload) {
            state.enums.drinking = payload
        },
        SET_ENUMS_EDUCATIONAL_LEVEL(state, payload) {
            state.enums.educationalLevel = payload
        },
        SET_ENUMS_ETHNICITY(state, payload) {
            state.enums.ethnicity = payload
        },
        SET_ENUMS_GENDER(state, payload) {
            state.enums.gender = payload
        },
        SET_ENUMS_INCOME_LEVEL(state, payload) {
            state.enums.incomeLevel = payload
        },
        SET_ENUMS_INTEREST(state, payload) {
            state.enums.interest = payload
        },
        SET_ENUMS_RELATIONSHIP_STATUS(state, payload) {
            state.enums.relationshipStatus = payload
        },
        SET_ENUMS_SMOKING(state, payload) {
            state.enums.smoking = payload
        },
        SET_ENUMS_ZODIAC(state, payload) {
            state.enums.zodiacSign = payload
        },
        SET_PROFILE(state, payload) {
            state.profile = payload
        },
        SET_AUTH_USER(state, payload) {
            state.authUser = payload
        }
    },
    getters: {},
    actions: {
        async loadUserStatistics({ commit }, params, config = { headers: {} }) {
            config.params = params
            const newConfig = setupHeaderConfig(config)
            return await axios.get('admin-panel/users/statistics', newConfig).then((response) => {
                commit('LOAD_USER_STATISTICS', response.data.data)
            })
        },
        async loadUserList({ commit }, params, config = { headers: {} }) {
            config.params = params
            const newConfig = setupHeaderConfig(config)
            return await axios.get('admin-panel/users/getUser', newConfig).then((response) => {
                commit('LOAD_USERS', response.data.data)
                return response.data
            })
        },
        async getEnums({ commit }, params, config = { headers: {} }) {
            config.params = params
            const newConfig = setupHeaderConfig(config)
            return await axios.get('/step-2', newConfig).then((response) => {
                commit('SET_ENUMS_CHILDREN', response.data.data.any_children)
                commit('SET_ENUMS_BODY_TYPE', response.data.data.body_type)
                commit('SET_ENUMS_DRINKING', response.data.data.drinking)
                commit('SET_ENUMS_EDUCATIONAL_LEVEL', response.data.data.educational_level)
                commit('SET_ENUMS_ETHNICITY', response.data.data.ethnicity)
                commit('SET_ENUMS_GENDER', response.data.data.gender)
                commit('SET_ENUMS_INCOME_LEVEL', response.data.data.income_level)
                commit('SET_ENUMS_INTEREST', response.data.data.interest)
                commit('SET_ENUMS_RELATIONSHIP_STATUS', response.data.data.relationship_status)
                commit('SET_ENUMS_SMOKING', response.data.data.smoking)
                commit('SET_ENUMS_ZODIAC', response.data.data.zodiac_sign)
            })
        },
        async updatePassword({ commit }, params, config = { headers: {} }) {
            const newConfig = setupHeaderConfig(config);
            return await axios.post('admin-panel/users/reset-password', params, newConfig);
        },
        async deleteAccount({ commit }, params, config = { headers: {} }) {
            const newConfig = setupHeaderConfig(config);
            return await axios.delete(`admin-panel/users/${params.id}`, newConfig);
        },
        async loadUserProfile({ commit }, params, config = { headers: {} }) {
            const newConfig = setupHeaderConfig(config);
            return await axios.get(`admin-panel/users/get-profile/${params.value}`, newConfig).then((response) => {
                commit("SET_PROFILE", response.data.data);
                return response.data.data;
            })
        },
        async deleteUserPhotos({ commit }, params, config = { headers: {} }) {
            const newConfig = setupHeaderConfig(config);
            return await axios.post(`admin-panel/users/delete-photos/${params.id}`, params, newConfig).then((response) => {
                return response.data;
            })
        },
        async updateProfile({ commit }, params, config = { headers: {} }) {
            const newConfig = setupHeaderConfig(config);
            return await axios.patch(`admin-panel/users/${params.id}`, params, newConfig);
        },
        async updatePreference({ commit }, params, config = { headers: {} }) {
            const newConfig = setupHeaderConfig(config);
            return await axios.patch(`admin-panel/users/${params.id}/preference`, params, newConfig);
        },
        async postInterest({ commit }, params, config = { headers: {} }) {
            const newConfig = setupHeaderConfig(config);
            return await axios.post('admin-panel/interest', params, newConfig);
        },
        async searchUser({ commit }, params, config = { headers: {} }) {
            const newConfig = setupHeaderConfig(config);
            return await axios.get(`admin-panel/users/browse?${params}`, newConfig).then((response) => {
                commit('LOAD_USERS', response.data.data)
                return response.data;
            })
        },
        async saveNotes({ commit }, params, config = { headers: {} }) {
            const newConfig = setupHeaderConfig(config);
            return await axios.post('admin-panel/users/notes', params, newConfig);
        },
        async userHeaderSearch({ commit }, params, config = { headers: {} }) {
            const newConfig = setupHeaderConfig(config);
            return await axios.get(`admin-panel/users/search?${params}`, newConfig).then((response) => {
                commit('LOAD_USERS', response.data.data)
                return response.data;
            });
        },
        async resendActivationEmail({ commit }, params, config = { headers: {} }) {
            const newConfig = setupHeaderConfig(config)
            return await axios.post('admin/resend-activation-email', params, newConfig);
        },
        async setAdmin({ commit }, params, config = { headers: {} }) {
            const newConfig = setupHeaderConfig(config)
            return await axios.post('admin/assign-role', params, newConfig);
        },
        async unsetAdmin({ commit }, params, config = { headers: {} }) {
            const newConfig = setupHeaderConfig(config)
            return await axios.post('admin/unset-role', params, newConfig);
        },
    },
});