<template>
  <div :id="modalId" class="modal fade">
    <div class="modal-dialog modal-dialog-centered" :class="size">
      <slot name="modalContent">
        <div class="modal-content" :class="profile ? 'profile' : ''">
          <div v-if="title !== ''" class="modal-header" :class="headerStyle">
            <h4 class="modal-title">
              {{ title }}
            </h4>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <slot name="modalBody" />
          <slot name="modalFooter" />
        </div>
      </slot>
    </div>
  </div>
</template>

<script>
import $ from "jquery";

export default {
  props: {
    modalId: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    size: {
      type: String,
      default: "modal-lg",
    },
    headerStyle: {
      type: String,
      default: "",
    },
    profile: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const closeModal = () => {
      $("#" + props.modalId).modal("hide");
    };

    return {
      closeModal,
    };
  },
};
</script>
<style scoped>
.profile {
  background-color: #262626;
  color: #d8d8d8;
}
.bg-linear {
  background: linear-gradient(90deg, #b28f49 0%, #d6caaa 100%);
  color: #fff;
  border-radius: 4px 4px 0 0;
}
</style>
