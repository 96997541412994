import { ref } from 'vue'

export function userEnums() {
    const memberships = ref([
        'Groups',
        'Events',
        'Huddles',
        'Deals'
    ])

    const userActions = ref([
        { label: 'Reset Password', link: 'reset-password' },
        { label: 'Edit User Information', link: 'update-profile' },
        { label: 'Message', link: 'message' },
        { label: 'Flag', link: 'flag' },
        { label: 'Suspend', link: 'suspend' },
        { label: 'Deactivate', link: 'deactivate' },
        { label: 'Reactivate', link: 'reactivate' },
        { label: 'Delete', link: 'delete' }
    ])

    const takeAction = ref([
        { label: 'Edit Group', link: 'edit' },
        { label: 'Flag', link: 'flag' },
        { label: 'Suspend', link: 'suspend' },
        { label: 'Deactivate', link: 'deactivate' },
        { label: 'Delete', link: 'delete' }
    ])

    const takeEventAction = ref([
        { label: 'Edit Event', link: 'edit' },
        { label: 'Flag', link: 'flag' },
        { label: 'Suspend', link: 'suspend' },
        { label: 'Publish', link: 'publish' },
        { label: 'Deactivate', link: 'deactivate' },
        { label: 'Delete', link: 'delete' }
    ])

    const UserCSVHeader = ref([{
            id: 'full_name',
            name: 'Full Name'
        },
        {
            id: 'email',
            name: 'Email'
        },
        {
            id: 'number',
            name: 'Number'
        },
        {
            id: 'location',
            name: 'Location'
        },
        {
            id: 'status',
            name: 'Status'
        },
        {
            id: 'last_sign_in',
            name: 'Last Sign In'
        },
        {
            id: 'notes',
            name: 'Notes'
        }
    ])
    const GroupCSVHeader = ref([{
            id: 'description',
            name: 'Description'
        },
        {
            id: 'group_owner',
            name: 'Group Owner'
        },
        {
            id: 'total_members',
            name: 'Number of Members'
        },
        {
            id: 'status',
            name: 'Status'
        },
        {
            id: 'notes',
            name: 'Notes'
        }
    ])

    const EventCSVHeader = ref([{
            id: 'description',
            name: 'Description'
        },
        {
            id: 'event_owner',
            name: 'Event Owner'
        },
        {
            id: 'total_members',
            name: 'Number of Members'
        },
        {
            id: 'status',
            name: 'Status'
        },
        {
            id: 'notes',
            name: 'Notes'
        }
    ])

    return {
        memberships,
        userActions,
        UserCSVHeader,
        takeAction,
        GroupCSVHeader,
        EventCSVHeader,
        takeEventAction
    }
}

export const GENERALSTATUS = Object.freeze({
    VERIFIED: 1, //'verified';
    PUBLISHED: 2, //'published';
    DEACTIVATED: 3, //'deactivated';
    FLAGGED: 4, //'flagged';
    UNPUBLISHED: 5, //'unpublished';
    SUSPENDED: 6, //'suspended';
    DELETED: 7, //'deleted';
    UNVERIFIED: 8, //'unverified';
    REINSTATED: 9, //'reinstated';
    REACTIVATED: 10 //'reactivated';
});

export const TAKEACTION = Object.freeze({
    SUSPEND: 'suspend',
    DEACTIVATE: 'deactivate',
    DELETE: 'delete',
    REACTIVATE: 'reactivate',
    PUBLISH: 'publish',
    UNSUSPEND: 'unsuspend',
    REMOVE_FLAG: 'remove-flag',
    ACTIVE: 'active',
    FLAG: 'flag'
});

export const useGeneralStatus = () => {
    const statusText = {
        [GENERALSTATUS.VERIFIED] : 'Verified',
        [GENERALSTATUS.PUBLISHED]: 'Active',
        [GENERALSTATUS.DEACTIVATED]: 'Deactivated',
        [GENERALSTATUS.FLAGGED]: 'Flagged',
        [GENERALSTATUS.UNPUBLISHED]: 'Unpublished',
        [GENERALSTATUS.SUSPENDED]: 'Suspended',
        [GENERALSTATUS.DELETED]: 'Deleted',
        [GENERALSTATUS.REINSTATED]: 'Reinstated',
        [GENERALSTATUS.REACTIVATED]: 'Reactivated'
    }

    const dynamicGeneralStatus = (statusId) => {
        return statusText[statusId]
    }

    return {
        dynamicGeneralStatus,
    }
}
