<template>
  <div class="card card-success">
    <div class="card-body">
      <header-stories-component
        :module-name="`user`"
        :search-for="`users`"
        :query-type="queryType"
        :total="$store.state.user.userStatistics[0].data"
        @done="filterAdvance($event)"
        @search-filter="searchHeader($event)"
        @reset="reset"
      />
    </div>
  </div>
  <div class="card card-success">
    <div class="card-body color-gray">
      <div class="row">
        <div class="col-md-12 col-lg-8 col-xl-9">
          <div class="row">
            <statistics-card-component
              :card="statics"
              :type="`users`"
              @change-status="changeStatus($event)"
            />
          </div>
        </div>
        <div class="col-md-12 col-lg-4 col-xl-3 border-start">
          <div class="row downloadAndMessageAlign">
            <div class="col-sm-6">
              <csv-button-component
                :csv-header="UserCSVHeader"
                :type="`users`"
              />
            </div>
            <div class="col-sm-6">
              <messenger
                :message-to="`User`"
                :select-message-title="`Who would you like to send a message to?`"
                :message-title="`Message User`"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <user-table
    :user-type="dataTableUser"
    :filter="filterAddress"
    :header-filter="headerFilter"
    @done="isDone($event)"
    :query-type="queryType"
    :query-filter="queryFilter"
  />
  <div id="confirm" />
</template>

<script>
import { computed, ref } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import moment from "moment";
import { userEnums } from "@/composable/enums.js";
import UserTable from "@/components/Tables/UserTable.vue";
import CsvButtonComponent from "@/components/CsvButtonComponent.vue";
import StatisticsCardComponent from "@/components/StatisticsCardComponent.vue";
import HeaderStoriesComponent from "@/components/HeaderStoriesComponent.vue";
import Messenger from "../Message/Messenger.vue";

export default {
  name: "App",
  components: {
    UserTable,
    CsvButtonComponent,
    StatisticsCardComponent,
    HeaderStoriesComponent,
    Messenger,
  },
  setup() {
    const status = ref("active");
    const { UserCSVHeader } = userEnums();
    const store = useStore();
    const filterAddress = ref(null);
    const headerFilter = ref(null);

    const route = useRoute();
    const queryType = route.query.type;
    const queryFilter = route.query.filter;

    const statics = ref([
      {
        label: "Active",
        value: store.state.user.userStatistics[0].heading.Active,
      },
      {
        label: "Unverified",
        value: store.state.user.userStatistics[0].heading.Unverified,
      },
      {
        label: "Verified",
        value: store.state.user.userStatistics[0].heading.Verified,
      },
      {
        label: "Deactivated",
        value: store.state.user.userStatistics[0].heading.Deactivated,
      },
      {
        label: "Flagged",
        value: store.state.user.userStatistics[0].heading.Flagged,
      },
      {
        label: "Suspended",
        value: store.state.user.userStatistics[0].heading.Suspended,
      },
      {
        label: "Deleted",
        value: store.state.user.userStatistics[0].heading.Deleted,
      },
    ]);

    const userList = computed(() => store.state.user.userList);
    const zodiac = computed(() => store.state.user.enums.zodiacSign);
    const dataTableUser = ref("");
    const isLoading = ref(false);

    const changeStatus = (event) => {
      dataTableUser.value = event;
    };

    const getZodiac = (zodiacNumber) => {
      const getZodiac = zodiac.value.find(
        (zodiac) => zodiac.id === parseInt(zodiacNumber)
      );
      return getZodiac.value;
    };

    const isDone = (event) => {
      isLoading.value = event;
    };

    const searchHeader = (event) => {
      dataTableUser.value = "headerFilter";
      headerFilter.value = event;
    };

    const filterAdvance = (data) => {
      filterAddress.value = data;
    };

    const reset = () => {
      dataTableUser.value = "all";
      // headerFilter.value = null;
      // filterAddress.value = null;
    };

    return {
      changeStatus,
      status,
      UserCSVHeader,
      statics,
      userList,
      getZodiac,
      zodiac,
      dataTableUser,
      isLoading,
      isDone,
      filterAddress,
      filterAdvance,
      searchHeader,
      headerFilter,
      reset,
      queryType,
      queryFilter,
    };
  },
  created: function () {
    this.moment = moment;
  },
};
</script>

<style scoped>
.downloadAndMessageAlign {
  text-align: center;
  position: inherit;
  margin-top: 15px;
}
.dropdown-menu-lg {
  min-width: auto;
}
.table td,
.table th {
  border-top: 0 !important;
}
.table td {
  vertical-align: middle;
}
.custom-control-label {
  font-weight: 500 !important;
}
.send {
  color: rgb(4, 98, 193);
}
.selected-message-type {
  background-color: #333;
  color: #cacaca !important;
}
</style>
